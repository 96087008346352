<template>
  <v-card>
    <v-card-title class="justify-space-between flex-nowrap">
      <div class="d-flex align-center">
        <v-icon
          aria-hidden="true"
          class="mr-3 material-icons-outlined"
          color="#1D428A"
        >
          school
        </v-icon>
        <div>
          <h2 class="title font-weight-bold text-subtitle-2 text-sm-h6">
            Mandatory Training Reminders
          </h2>
          <div
            class="warning--text"
            style="font-size: 1rem; line-height: 16px; font-weight: 500"
          >
            <!-- Please allow 2 working days for Q+ mandatory training data to
            refresh from MyPL. -->
          </div>
        </div>
      </div>
      <div>
        <v-btn
          aria-label="More information"
          icon
          class="d-print-none text-decoration-none size--44"
          @click="openInfoDialog = true"
        >
          <v-icon
            color="primary"
            dark
          >
            mdi-help-circle-outline
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <hr>
    <v-card-text
      class="text-body-1 dark--text secondary"
    >
      <p>From <b>Term 1 2025</b>, mandatory training is going to change. </p>
      <p>Q+ will be updated to reflect these changes.</p>
      <p>
        Visit 
        <a 
          href="https://education.nsw.gov.au/inside-the-department/human-resources/mandatory-training/changes-to-mandatory-training"
          target="_blank"
        >Mandatory training in 2025
        </a> for the most up to date information.
        Select the "<b>Go to MyPL</b>" button below to access your personal mandatory training record.
      </p>
    </v-card-text>
    <!-- <v-card-text class="pa-0">
      <v-data-table
        class="mandatory-training-table"
        :headers="mandatoryTrainingRemindersHeaders"
        :items="filteredMandatoryTrainingList"
        v-bind="$attrs"
        :sort-by="['dueDate']"
        :sort-asce="true"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:item.dueDate="{ item }">
          <div
            v-if="item.complianceStatusCode === 'KS_SCNC'"
            class="d-flex justify-end align-center"
          >
            <v-icon
              aria-hidden="true"
              class="error--text mr-3"
            >
              error
            </v-icon>
            <div class="error--text font-weight-bold text-end">
              Expired
            </div>
          </div>
          <div v-if="item.complianceStatusCode === 'KS_SCSV'">
            <div
              :class="
                isCloseToDate(item.dueDate)
                  ? 'warning--text font-weight-bold'
                  : ''
              "
              class="text-end"
            >
              {{ formatDate(item.dueDate) }}
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text> -->
    <Dialog
      :openDialog="openInfoDialog"
      maxWidth="600px"
      title="Mandatory Training Reminders"
      icon="info_outline"
      :displayCloseBtn="true"
      @close="closeTableInfo"
      :return-handler="closeTableInfo"
    >
      <div
        slot="text"
        class="pa-5 pt-0 ml-16"
      >
        <p
          v-if="dashboardContent"
          v-html="dashboardContent.mandatory_training_reminders_tooltip"
        />
      </div>
    </Dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { Dialog } from "@nswdoe/doe-ui-core";
import moment from "moment";
import _ from "lodash";

const MandatoryTrainingReminders = {
  data() {
    return {
      mandatoryTrainingRemindersHeaders: [
        { text: "Name", value: "itemName" },
        { text: "Due Date", value: "dueDate", width: 180 },
      ],
      loading: false,
      openInfoDialog: false,
    };
  },
  components: {
    Dialog,
  },
  computed: {
    ...mapGetters({
      mandatoryTrainingList: "moduleUserProfile/mandatoryTrainingList",
    }),
    ...mapState("moduleStaticContent", {
      dashboardContent: (state) => state.content.dashboard,
    }),
    filteredMandatoryTrainingList() {
      if (this.mandatoryTrainingList.length) {
        //Get all lists within one array and remove Certified and N/A list
        let mergedLists = [];
        const notMergedLists = this.mandatoryTrainingList.map(
          (item) => item.staffAccreditations
        );
        _.forEach(notMergedLists, (item) => {
          _.filter(item, (list) => {
            if (
              list.complianceStatusCode === "KS_SCNC" ||
              list.complianceStatusCode === "KS_SCSV"
            ) {
              mergedLists.push(list);
            }
          });
        });

        //Remove duplicate and compare due date with same name
        let nonDuplicatedList = [];
        const notCertifiedList = _.uniqBy(
          _.filter(
            mergedLists,
            (list) => list.complianceStatusCode === "KS_SCNC"
          ),
          "itemName"
        );
        const removedNotCertifiedList = _.filter(
          mergedLists,
          (list) => list.complianceStatusCode !== "KS_SCNC"
        );
        _.filter(removedNotCertifiedList, (list1) =>
          _.filter(removedNotCertifiedList, (list2) => {
            if (list1.itemName === list2.itemName) {
              if (moment(list1.dueDate).isAfter(moment(list2.dueDate))) {
                nonDuplicatedList.push(list2);
              }
              nonDuplicatedList.push(list1);
            }
          })
        );
        const unfilteredList = notCertifiedList.concat(
          _.uniqBy(nonDuplicatedList, "itemName")
        );

        //Remove courses that are not for the teaching and school-based staff
        //Further trim down the list to exclude on-off courses
        const mandatoryItemCodes = [
          "SC_22",    //Aboriginal Cultural Education - Let’s take the first step together Days left
          "SC_3",     //Anaphylaxis e-learning days left until expiry
          "SC_19",    //Anti-Racism Policy Training days left
          "SC_4",     //Annual Child Protection Training days left until expiry
          // "SC_7",     //Code of Conduct days left until expiry
          "SC_15",    //Reporting Data Breaches days left until expiry
          "SC_9",     //E-Emergency days left until expiry
          "SC_1",     //Corruption Prevention days left until expiry
          "SC_20",    //Cyber security training - all staff
        ];
        const nsbtsMandatoryItemCodes = [
          "CSC_14",   //Aboriginal Cultural Education - Let’s take the first step together
          "CSC_5",    //Cyber Security NSW Essentials Training
          "CSC_1",    //Anti-Racism Policy Training
          // "CSC_4",    //Code of Conduct Online
          "CSC_7",    //Reporting Data Breaches
          "CSC_6",    //Fraud and Corruption Control
          "CSC_8",    //Work Health & Safety Induction for Employees
        ];

        const schoolsList = unfilteredList.filter((item) => {
          return mandatoryItemCodes.includes(item.itemCode);
        });

        const corpList = unfilteredList.filter((item) => {
          return nsbtsMandatoryItemCodes.includes(item.itemCode);
        });

        if (corpList.length > 0) {
          return corpList;
        } else {
          return schoolsList;
        }
      }
      return [];
    },
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("moduleUserProfile/fetchMandatoryTrainingList")
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    formatDate(itemDate) {
      let itemCorrectDate = itemDate.split("-");
      return (
        itemCorrectDate[2] + "/" + itemCorrectDate[1] + "/" + itemCorrectDate[0]
      );
    },
    isCloseToDate(itemDate) {
      let itemDue = moment(itemDate);
      return (
        itemDue.diff(moment(), "months") < 1 &&
        itemDue.diff(moment(), "days") > 0
      );
    },
    closeTableInfo() {
      this.openInfoDialog = false;
    },
  },
};
export default MandatoryTrainingReminders;
</script>

<style lang="scss" scoped>
.mandatory-training-table {
  // max-height: 380px;
  overflow: auto;
}

.size--44 {
  height: 44px;
  width: 44px;
}

::v-deep {
  .v-data-table-header-mobile {
    display: none;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:last-child {
    text-align: end !important;
    color: #002664 !important;
  }
}

@media print {
  .mandatory-training-table {
    max-height: max-content;
    overflow: initial;
  }
}
</style>
